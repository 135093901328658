.svg-wrapper {
    text-align: center;
    height: auto;
    /* margin-top: -90px; */
}

.svg-map {
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    stroke-width: 1px;
    stroke: white;
    width: 50vw;
    overflow: visible !important;
    fill: #5e7297;

}

.region-svg-map {
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    stroke-width: 1px;
    stroke: white;
    overflow: visible !important;
    fill: #5e7297;
    width: 30vw;
    height: auto;
    max-height: 60vh;
    position: relative;
    bottom: 60px;
    margin-top: 70px;
}

.region:hover {
    fill: #3f4d67;
    opacity: 4.6;
}

.bar-wrapper {
    /* position: absolute; */
    margin-top: 8px;
}

.right-card {
    width: 100%;
    margin-top: 16px;
}

.orgInfo {
    font-weight: bold;
    font-size: 14px;
    color: rgba(39, 65, 120, 0.9);
    padding: 8px 6px;
    cursor: pointer;
    display: flex;
}

.orgInfo:nth-child(even) {
    background-color: rgb(241, 251, 255);
}

.key {
    flex: 0.3;
}

.value {
    flex: 0.7;
    font-weight: normal;
}

@media only screen and (max-width: 1100px) {
    .svg-map {
        width: 60vw;

    }

    .region-svg-map {
        width: 50vw;

    }
}

@media only screen and (max-width: 991px) {
    .svg-map {
        width: 70vw;

    }

    .region-svg-map {
        width: 60vw;

    }
}

@media only screen and (max-width: 786px) {
    .svg-map {
        width: 80vw;

    }

    .region-svg-map {
        width: 80vw;
    }
}
